.AnnouncementBar{
    position: sticky;
    background-color: #26150a;
    min-width: 100%;
    top: 0;
    left: 0;
    z-index: 5;
}

.AnnouncementBar__Wrapper{
    padding: 12px 15px;
    color: white;
    font-size: 0.8rem;
    margin: auto;
}

