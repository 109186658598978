/* .img-container{
    width: 100%;
    height: 100vh ;
    background-color: rgba(0, 0, 0, 0.34);
    z-index: 2;
}

.Bottombg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: none;
    
} */

.bottom-text{
    display: flex;
    width: 100%;
    height: 80vh;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.305),rgba(44, 44, 44, 0.336)
    ),url(https://www.furug.com/cdn/shop/files/new_homepage_image_1500x.png?v=1613608341);
    opacity: 1;
    background-position: center;
    background-size: cover;
    text-align: center;
    align-items: center;
    justify-content: center;

}

.bottom-text span{
    color: white;
    font-size: 1.4rem;
    line-height: 2.3rem;
}


