.footer-container{
    display: flex;
    justify-content: space-between;
    margin: 2rem;
    padding: 2rem;
    flex-wrap: wrap;
    color: rgb(77, 77, 77);
}

.icons img{
    width: 6rem;
} 


.flexcol{
    display: flex;
    flex-direction: column;
    text-align: left;
}

.first{
    max-width: 20rem;
    gap: 1.5rem;
    margin-bottom: 3rem;
}

.second{
    max-width: 20rem;
    gap: 1.5rem;
    margin-bottom: 3rem;
}

.third{
    max-width: 20rem;
    gap: 1.5rem;
    margin-bottom: 3rem;
}

.fourth{
    max-width: 15rem;
    gap: 2rem;
    margin-bottom: 3rem;
}

input{
    width: 70%;
    padding: 1rem;
    font-size: 0.9rem;
}

button{
    width: 50%;
    border: none;
    padding: 1rem;
    color: rgb(255, 255, 255);
    background-color: rgb(255, 62, 62);
}

