.image{
    height: 500px;
    width: 100%;
}

.main-img {
    /* display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center; */
    /* background-size: cover;
    background-repeat: no-repeat;
    max-width: 100%;
    min-height: 100%; */

    width: 100%;
    height: 100%;
    object-fit: cover;
}

