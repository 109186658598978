.para{
    min-height: 200px;
    padding: 3rem;
}

.para-section{
    margin: 2rem;
}
.para h3{
    font-size: 1.6rem;
    font-weight: 400;
    margin: 2rem;
}

.para p{
    line-height: 1.5rem;
    color: rgb(91, 91, 91);
}