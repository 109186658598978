.top {
  font-size: 1.6rem;
  margin: 5rem;
}

.new-arrivals {
  margin: 1.4rem;
  margin-bottom: 6rem;
}

.cart {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 5rem;
}

.item {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 20rem;
  height: 25rem;
  margin-bottom: 2rem;
  box-sizing: border-box;
  /* border: 2px solid rgb(88, 66, 50); */
}

.item img {
  width: 100%;
}

.item > :nth-child(2) {
  margin-left: 1.6rem;
  margin-bottom: 0.3rem;
  margin-top: 0.8rem;
}

.item > :nth-child(3) {
  margin-left: 1.6rem;
  margin-bottom: 0.3rem;
}

.top-Buttom span {
  padding: 16px 45px 16px 45px;
  background-color: rgb(255, 69, 69);
  font-size: 1.2rem;
  color: rgb(255, 255, 255);
  text-align: center;
}

/* @media (max-width: 429px) {
  .top-Buttom span {
    padding: 16px 45px 16px 45px;
    background-color: rgb(255, 69, 69);
    font-size: 1.2rem;
    color: rgb(255, 255, 255);
    text-align: center;
  }
} */
