.product-main{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: 1.3rem;
    
}


.image-text{
    height: 700px;
    width: 700px;
    /* border: 2px solid red; */
}


.image-text img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product{
    position: relative;
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.product span{
    font-size: 1.5rem;
}

.product >:nth-child(2){
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 140px;
    /* right: 306px;  */
    color: white;
}


.product >:nth-child(3){
    padding: 16px 45px 16px 45px;
    background-color: rgb(255, 255, 255);
    font-size: 0.9rem;
    color: rgb(0, 0, 0);
    text-align: center;
    position: absolute;
    bottom: 60px;
    /* right: 254px;  */
} 


@media (max-width : 733px) {

    .image-text{
        height: 500px;
        width: 400px;
        margin-left: 10px;
        margin-right: 10px;
        box-sizing: border-box;
    }

}



@media (max-width : 429px) {
    .image-text{
        height: 400px;
        width: 300px;
        margin-left: 5px;
        margin-right: 5px;
        justify-content: center;
    }

    .product >:nth-child(2){
        
        bottom: 100px;
        
    }
    
    
    .product >:nth-child(3){
        bottom: 30px;
        /* right: 254px;  */
    } 
    
    


}